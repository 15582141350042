import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./services.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import { deleteSkill_api, fetchAllSkills, service_condition_api, status_update_skill_api } from "../api/skill";
import { getBaseUrl2 } from "../../utils";
import { get_condition_api } from "../api/condition";

const AllSkill = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allskill, setallskill] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [allCondition, setAllCondition] = useState([]);
  useEffect(() => {
    fetchallskillFunc();
  }, [pageCount, search]);

  console.log(allskill, "allskill-----------------------------")
  console.log(allCondition, "allCondition-----------------------------")
  async function fetchallskillFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchAllSkills(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.toatal_page);
        setallskill(res.data.data.services);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deletearticleFunc() {
    setisLoading(true);

    try {
      let res = await deleteSkill_api(deleteId);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallskillFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const skill_status = async (data) => {
    const id = data._id;
    setisLoading(true);
    const fd = new FormData();
    fd.append("status", data.status == true ? false : true);

    try {
      let res = await status_update_skill_api(id, fd);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchallskillFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  useEffect(() => {
    fetchAllExpertiseFunc();
  }, [pageCount]);

  async function fetchAllExpertiseFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        // id: type
      };
      let res = await get_condition_api(temp);
      if (res.data.status) {
        // setpageLength(res?.data?.data?.toatal_page);
        setAllCondition(res?.data?.data.conditions);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      setisLoading(false)
      console.log(error);
    }
  }

  const selectConditionFunc = async (id) => {
    console.log(id, "id for the split", id.split("--"))
    const [tag, paramsId] = id?.split("--")
    // console.log(tag, paramsId)
    let temp = {
      tags: [tag]
    }
    try {
      let res = await service_condition_api(paramsId, temp);//delete id is used for add services in condition crud
      if (res.data.status) {
        fetchallskillFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  }


  let importFunc = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    try {
      let res = await importjson(file);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const importjson = () => { };
  return (
    <div className="container">
      <div className={s["article-list-title"]}>
        <h3>Services List</h3>
      </div>
      <div className={s["user-list-heading"]}>
        <div className={s["user-list-title"]}>
          <div className="beat_left" style={{ display: "flex", gap: "1rem" }}>
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-services", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Services
            </div>
            {/* <div>
              <label className={s["title"]}>
                <span>Import</span>
                <input type="file" accept=".xlsx, .xls, .csv" onChange={importFunc} name="file" style={{ display: "none" }} />
              </label>
            </div> */}
          </div>
        </div>
        <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search by Service name..."
            />
          </div>
          <div className={s["filter-btn"]}>
            <span style={{ paddingRight: "2px" }}>
              <BiFilter size={20} />
            </span>
            Filter
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Parent Category</StyledTableCell>
              <StyledTableCell align="center">Sub Category</StyledTableCell>
              <StyledTableCell align="center">service_mode</StyledTableCell>
              <StyledTableCell align="center">service_charge(₹)</StyledTableCell>
              <StyledTableCell align="center">Condition List</StyledTableCell>
              <StyledTableCell align="center">consult_charge</StyledTableCell>
              <StyledTableCell align="center">Service Type</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allskill?.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell>{row.image ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row?.image} alt="" /> : null}</StyledTableCell>
                <StyledTableCell>{row.name} </StyledTableCell>
                <StyledTableCell align="center">{row?.category?.name}</StyledTableCell>
                <StyledTableCell align="center">{row?.sub_category?.name}</StyledTableCell>
                <StyledTableCell align="center">{row.service_mode}</StyledTableCell>
                <StyledTableCell align="center">{row.service_charge}</StyledTableCell>
                <StyledTableCell align="center">
                  <select name=""
                    onChange={(e) => selectConditionFunc(e.target.value)}
                  // value={(() => {
                  //   const matchedCondition = allCondition.find(data => row?.tags[0] === data?._id);
                  //   console.log(matchedCondition, "matchedCondition0000000000000000000000000000000000000000000000000000000000000000")
                  //   return matchedCondition ? `${matchedCondition?.name}` : "";
                  // }
                  // )()}
                  // value={
                  //  allCondition?.filter((data)=> row?.tags[0] === data?._id)
                  //  .map((data)=>data.name)
                  // }
                  >
                    <option>Select condition</option>
                    <option value={`${""} --${row._id} `}>remove this service</option>
                    {
                      allCondition
                        .filter(data => row?.category?._id === data?.category)
                        .map(data => (
                          <option
                            key={data._id}
                            value={`${data._id}--${row._id}`}
                            selected={`${data._id}--${row._id}` === `${row.tags[0]}--${row._id}`}
                          >
                            {data?.name}
                          </option>
                        ))
                    }
                  </select>
                </StyledTableCell>
                <StyledTableCell align="center">{row.consult_charge}</StyledTableCell>
                <StyledTableCell align="center">{`${row?.bestTreatment === true ? "Array Of Beauty Treatment" : row?.popularProduct === true ? "Innovative Beauty Treatment" : "Normal Service"} `}</StyledTableCell>
                <StyledTableCell align="center">
                  <div style={{ cursor: "pointer" }} onClick={() => skill_status(row)} className={`${row.status === true ? s.active_admin : s.inactive_admin} `}>
                    {row.status === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/add-services", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletePopup(true);
                      setcurrentGroup(row);
                      setdeleteId(row._id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allskill.length <= 0 && <DataNotFound />}
        {allskill?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>
      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.skill_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deletearticleFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div >
  );
};

export default AllSkill;
