import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./testimonial.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { admin_vendorstatus_api, deleteTestimonial_api, getTestimonials_api, status_updateTestimonial_api } from "../api/testimonial";
import { send_vendor_msg_api } from "../api/admin";
import { MdMessage } from "react-icons/md";
import Vendormessage from "../Dialogbox/Vendormessage";
const VendorList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [alltestimonial, setalltestimonial] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [vendormsgpopup, setvendormsgpopup] = useState(false);
  const [vendordata, setvendordata] = useState("");
  useEffect(() => {
    fetchalltestimonialFunc();
  }, [pageCount, search]);

  async function fetchalltestimonialFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getTestimonials_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setalltestimonial(res?.data?.data.vendors);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteroleFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteTestimonial_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const testimonial_status = async (data, e) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await status_updateTestimonial_api(data._id);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };
  const admin_vendorstatus = async (data, e) => {
    setisLoading(true);
    const temp = {
      status: data.status == true ? "false" : "true",
    };

    try {
      let res = await admin_vendorstatus_api(temp, data._id);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };
  const send_vendor_msg = async (data) => {
    setisLoading(true);
    const temp = {
      msg: "Hii",
      vendor_id: data._id,
    };

    try {
      let res = await send_vendor_msg_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <div className="container">
      <div className={s["user-list-heading"]}>
        <div className={s["role-list-title"]}>
          <h3>Vendor List</h3>
        </div>
        <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search name..."
            />
          </div>
          <div className={s["filter-btn"]}>
            <span style={{ paddingRight: "2px" }}>
              <BiFilter size={20} />
            </span>
            Filter
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="center">Image</StyledTableCell> */}
              <StyledTableCell align="center">Name</StyledTableCell>
              {/* <StyledTableCell align="center">City</StyledTableCell> */}
              <StyledTableCell align="center" style={{ width: "500px" }}>Sub_Categories</StyledTableCell>
              <StyledTableCell align="center">Services</StyledTableCell>
              <StyledTableCell align="center">Status1</StyledTableCell>
              <StyledTableCell align="center">Status2</StyledTableCell>
              <StyledTableCell align="center">Message</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
              <StyledTableCell align="center">Assign</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alltestimonial?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell style={{ cursor: "pointer" }} onClick={() => navigate("/vendor-details", { state: row })}>
                  <div>
                    <p><b>Name:</b>{row?.name}</p>
                    <p><b>Email:</b>{row?.email}</p>
                    <p><b>Phone:</b>{row?.phone}</p>
                    <p><b>City:</b>{row?.city}</p>
                    <p>-----------------------</p>
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell align="center" style={{ width: "40%" }}>
                  {row?.city}
                </StyledTableCell> */}
                <StyledTableCell style={{ width: "500px" }}>
                  <div>For Female:</div>
                  {
                    (row?.sub_categories_female).map((data, index) => (
                      <div>
                        {data?.name}
                        <p>---------------</p>
                      </div>
                    ))}
                  <div>For Male:</div>
                  {
                    (row?.sub_categories_male).map((data, index) => (
                      <div>
                        {data?.name}
                        <p>---------------</p>
                      </div>
                    ))
                  }
                </StyledTableCell>
                <StyledTableCell>
                  {
                    (row?.services).map((data, index) => (
                      <div>
                        {data?.name}
                        <p>---------------</p>
                      </div>
                    ))
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => admin_vendorstatus(row)}
                    className={`${row?.status === true ? s?.active_admin : s?.inactive_admin}`}
                  >
                    {`${row?.status === true ? "Active" : "InActive"}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => testimonial_status(row)}
                    className={`${row?.approved === true ? s?.active_admin : s?.inactive_admin}`}
                  >
                    {`${row?.approved === true ? "Approved" : "unapproved"}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setvendordata(row);
                    setvendormsgpopup(true);
                  }}
                >
                  <MdMessage size={20} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CiEdit
                    onClick={() => navigate("/vendor-profile", { state: row })}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button type="button" class="btn btn-primary" onClick={() => navigate("/allocate-to-vendor", { state: row })}>
                    Assign
                  </button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button type="button" class="btn btn-primary" onClick={() => navigate("/vendor-profile", { state: row })}>
                    View
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {alltestimonial?.length <= 0 && <DataNotFound />}
        {alltestimonial?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>

      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteroleFunc();
        }}
      />
      <Vendormessage open={vendormsgpopup} data={vendordata} close={() => setvendormsgpopup(!vendormsgpopup)} />
      <FilterPopup open={false} />

      <Loder loading={isLoading} />
    </div>
  );
};

export default VendorList;
