import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./user.module.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Pagination } from "@mui/material";
import { fetchAllUsers, user_delete_api, user_status_api } from "../api/user";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import DeletePopup from "../Dialogbox/DeletePopup";
import xlsx from "json-as-xlsx";
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";
const MainPage = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allUsers, setallUsers] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [exportBtnLoading, setexportBtnLoading] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [active, setactive] = useState("");
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllUsersFunc();
  }, [pageCount, search]);

  async function fetchAllUsersFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchAllUsers(temp);
      if (res.data.status) {
        setallUsers(res.data.data.users);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteuserFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await user_delete_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchAllUsersFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  let settings = {
    fileName: "Siama Users", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  const exportFunc = () => {
    if (allUsers.length < 1) {
      return notificationHandler({ type: "danger", msg: "All employee list is empty!" });
    }
    let data = [
      {
        sheet: "Adults",
        columns: [
          { label: "ID", value: (row) => (row.id ? row._id : "") },
          { label: "First_name", value: (row) => (row.first_name ? row.first_name : "") },
          { label: "Last_name", value: (row) => (row.last_name ? row.last_name : "") },
          { label: "Phone", value: (row) => (row.phone ? row.phone : "") },
          { label: "Email", value: (row) => (row.email ? row.email : "") },
          { label: "Address", value: (row) => (row.address ? row.address : "") },
        ],
        content: allUsers,
      },
    ];
    try {
      xlsx(data, settings, callback);
    } catch (error) {
      console.log(error);
    }
  };

  let callback = function (sheet) {
    console.log("Download complete:", sheet);
  };

  //filter code
  const sortByField = (array, field, order = "asc") => {
    const compareFunction = (a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      if (typeof aValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      let comparison = 0;

      if (aValue > bValue) {
        comparison = 1;
      } else if (aValue < bValue) {
        comparison = -1;
      }

      return order === "desc" ? comparison * -1 : comparison;
    };
    setallUsers([...array].sort(compareFunction));
    return [...array].sort(compareFunction);
  };

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Users List</h3>
          </div>

          <div className={s["user-list-search"]}>
            <div className={s["search-box"]} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setactive(null);
                  setsearch(e.target.value);
                }}
                placeholder="Search users by name.."
              />
            </div>
            <div>
              <label className={s["title"]} onClick={() => exportFunc()}>
                {exportBtnLoading ? <CircularProgress size={24} /> : "Export"}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                User ID
                <span
                  style={{ cursor: "pointer", color: `${active == 0 ? "red" : null}` }}
                  onClick={() => {
                    setactive(0);
                    sortByField(allUsers, "user_id");
                  }}
                >
                  <IoIosArrowRoundUp size={20} />
                </span>
                <span
                  style={{ cursor: "pointer", color: `${active == 1 ? "red" : null}` }}
                  onClick={() => {
                    setactive(1);
                    sortByField(allUsers, "user_id", "desc");
                  }}
                >
                  <IoIosArrowRoundDown size={20} />
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                Name
                <span
                  style={{ cursor: "pointer", color: `${active == 3 ? "red" : null}` }}
                  onClick={() => {
                    setactive(3);
                    sortByField(allUsers, "first_name");
                  }}
                >
                  <IoIosArrowRoundUp size={20} />
                </span>
                <span
                  style={{ cursor: "pointer", color: `${active == 4 ? "red" : null}` }}
                  onClick={() => {
                    setactive(4);
                    sortByField(allUsers, "first_name", "desc");
                  }}
                >
                  <IoIosArrowRoundDown size={20} />
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                Email
                <span
                  style={{ cursor: "pointer", color: `${active == 5 ? "red" : null}` }}
                  onClick={() => {
                    setactive(5);
                    sortByField(allUsers, "email");
                  }}
                >
                  <IoIosArrowRoundUp size={20} />
                </span>
                <span
                  style={{ cursor: "pointer", color: `${active == 6 ? "red" : null}` }}
                  onClick={() => {
                    setactive(6);
                    sortByField(allUsers, "email", "desc");
                  }}
                >
                  <IoIosArrowRoundDown size={20} />
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                Number
                <span
                  style={{ cursor: "pointer", color: `${active == 7 ? "red" : null}` }}
                  onClick={() => {
                    setactive(7);
                    sortByField(allUsers, "phone");
                  }}
                >
                  <IoIosArrowRoundUp size={20} />
                </span>
                <span
                  style={{ cursor: "pointer", color: `${active == 8 ? "red" : null}` }}
                  onClick={() => {
                    setactive(8);
                    sortByField(allUsers, "phone", "desc");
                  }}
                >
                  <IoIosArrowRoundDown size={20} />
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">Created</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allUsers.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.user_id} </StyledTableCell>
                <StyledTableCell style={{ cursor: "pointer" }} onClick={() => navigate("/user-details", { state: row })}>
                  {row.first_name + " " + row.last_name}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">{row.email} </StyledTableCell>
                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                <StyledTableCell align="center">{row.created_at}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allUsers.length <= 0 && <DataNotFound />}
        {allUsers?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>
      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteuserFunc();
        }}
      />
      <Loder loading={isLoading} />
    </div>
  );
};

export default MainPage;
