const getBaseUrl = () => {
  return "https://api.siama.in/";
  // return "http://192.168.1.15:3001/";
};
export { getBaseUrl };

const getBaseUrl2 = () => {
  return "https://api.siama.in/";
  // return "http://192.168.1.15:3001/";
};
export { getBaseUrl2 };
