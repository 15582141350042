import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const add_best_deal = getBaseUrl2() + "api/admin/bestDeal";
const best_deal = getBaseUrl2() + "api/admin/bestDeal";
const getroll_admin = getBaseUrl() + "role";
const send_vendor_msg = getBaseUrl2() + "api/admin/send_vendor_msg";

export const send_vendor_msg_api = async (data) => {
    let config = {
        method: "post",
        url: send_vendor_msg,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const getroll_admin_api = async (data) => {
    let config = {
        method: "get",
        url: getroll_admin,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const update_best_Deal = async (id, data) => {
    let config = {
        method: "PATCH",
        url: getBaseUrl2() + `api/admin/bestDeal/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const status_banner_api = async (id, data) => {
    let config = {
        method: "PATCH",
        url: getBaseUrl2() + `api/admin/banners/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const delete_bestDeal_api = async (id) => {
    let config = {
        method: "DELETE",
        url: getBaseUrl2() + `api/admin/bestDeal/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const create_best_deal = async (data) => {
    let config = {
        method: "post",
        url: add_best_deal,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const fetchbestDeal = async (data) => {
    let config = {
        method: "get",
        url: best_deal,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        // data: data,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};


export const status_bestDeal_api = async (id, data) => {
    let config = {
        method: "PATCH",
        url: getBaseUrl2() + `api/admin/bestDeal/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

