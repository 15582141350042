import React from "react";
import Adminadd from "./pages/Banneradd";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import Users from "./pages/Users";
import { ServicesList } from "./pages/ServicesList";
import AddServices from "./components/Services/AddServices";
import Category from "./pages/Category";
import AddCategoryPage from "./pages/AddCategoryPage";
import SubCategories from "./pages/SubCategories";
import AddSubCategories from "./pages/AddSubCategories";
// import Testimonial from "./pages/VenderPage";
import AddTestimonial from "./pages/AddTestimonial";
import VendorPage from "./pages/VendorPage";
import BookingList from "./pages/BookingList";
import AllocateVenderPage from "./pages/AllocateVendorPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import VendorDetails from "./components/Testimonial/VendorDetails";
import Banner from "./pages/Banner";
import Banneradd from "./pages/Banneradd";
import CallbackRequestsPage from "./pages/CallbackRequestsPage";
import VendorProfile from "./components/Testimonial/VendorProfile";
import ServiceRequestsPage from "./pages/ServiceRequestsPage";
import ConsultRequestsPage from "./pages/ConsultRequestsPage";
import OffersListPage from "./pages/OffersListPage";
import AddOffersPage from "./pages/AddOffersPage";
import ConditionPage from "./pages/ConditionPage";
import AddCondition from "./components/Conditions/AddCondition";
import FaqListPage from "./pages/FaqListPage";
import AddFaqPage from "./pages/AddFaqPage";
import BlogData from "./components/Blogs/BlogList";
import AddBlog from "./components/Blogs/AddBlog";
import BestDealList from "./components/BestDeal/BestDealList";
import AddBestDeal from "./components/BestDeal/AddBestDeal";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "banner", component: <Banner /> },
  { params: "users", component: <Users /> },
  { params: "booking-list", component: <BookingList /> },
  { params: "dashboard", component: <Dashboard /> },
  { params: "banneradd", component: <Banneradd /> },
  { params: "user-details", component: <UserDetailsPage /> },
  { params: "allocate-to-vendor", component: <AllocateVenderPage /> },
  { params: "profile", component: <Profile /> },
  { params: "service-list", component: <ServicesList /> },
  { params: "add-services", component: <AddServices /> },
  { params: "category-list", component: <Category /> },
  { params: "add-category", component: <AddCategoryPage /> },
  { params: "sub-categories-list", component: <SubCategories /> },
  { params: "add-sub-categories", component: <AddSubCategories /> },
  { params: "vendor-list", component: <VendorPage /> },
  { params: "vendor-details", component: <VendorDetails /> },
  { params: "testimonial-add", component: <AddTestimonial /> },
  { params: "call-request", component: <CallbackRequestsPage /> },
  { params: "vendor-profile", component: <VendorProfile /> },
  { params: "service-requests", component: <ServiceRequestsPage /> },
  { params: "consult-requests", component: <ConsultRequestsPage /> },
  { params: "offer-list", component: <OffersListPage /> },
  { params: "add-offer", component: <AddOffersPage /> },


  { params: "expertise-list", component: <ConditionPage /> },
  { params: "add-condition", component: <AddCondition /> },

  // faq
  { params: "faq-list", component: <FaqListPage /> },
  { params: "add-faq", component: <AddFaqPage /> },

  // Blogs
  { params: "blog-list", component: <BlogData /> },
  { params: "add-blog", component: <AddBlog /> },

  //  Best Deals
  { params: "best-deal", component: <BestDealList /> },
  { params: "add-best-deal", component: <AddBestDeal /> },

];

export default routeArray;
