import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import { useNavigate } from "react-router-dom";
import { fetchAllDashboard_api } from "../api/dashboard";
const Home = () => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState({})
  console.log(dashboard, "dashboard,--------------------")
  const a = [
    {
      title: "Today Order Count",
      value: dashboard.todayOrderCount
    },
    {
      title: "Today Revenue",
      value: dashboard.todayRevenue

    },
    {
      title: "Today User Count",
      value: dashboard.todayUserCount

    },
    {
      title: "Total Order Count",
      value: dashboard.totalOrderCount

    },
    {
      title: "Total Revenue",
      value: dashboard.totalRevenue

    },
    {
      title: "Total User Count",
      value: dashboard.totalUserCount

    },
    {
      title: "Total Vendor Count",
      value: dashboard.totalVendorCount

    }
  ];
  useEffect(() => {
    fetchAllDashboardFunc();
  }, [])

  const fetchAllDashboardFunc = async () => {
    let res = await fetchAllDashboard_api()
    console.log(res?.data?.data, "res data in the dashboard api=======================================")
    try {
      if (res.data.status) {
        setDashboard(res.data.data)
      } else {
        console.log("Status False!")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div style={{ paddingInline: "1rem" }}>
        <div className="dashboard-section">
          <div className={s["dashboard-header-container"]}>
            {a.map((data) => (
              <Card className={s["dashbord-content"]}>
                <div
                  class={s["dashboard-main-card"]}
                  style={{ cursor: "pointer" }}
                //  onClick={() => navigate(`/${data?.navigate}`)}
                >
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>{data.title}</h6>
                      <h4>{data.value}</h4>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        {/* <div className={s["graph-container"]}>
          <div className={s["graph-content"]}>
            <div className="graph-left" style={{ width: "70%" }}>
              <Graph1 />
            </div>
            <div className="graph-right" style={{ width: "30%" }}>
              <Graph />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Home;
