import React, { useState, useEffect } from "react";
import s from "./condition.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addCategory_api, getCategories_api, updateCategory_api } from "../api/category";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import Select from "react-select";
import { fetchAllSkills } from "../api/skill";
import { getBanners_api } from "../api/banner";
import { addCondition_api, updateCondition_api } from "../api/condition";
const AddCondition = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setname] = useState("");
    const [file, setfile] = useState(null);
    const [description, setdescription] = useState("");
    const [priority, setpriority] = useState("");
    const [categoryType, setCategoryType] = useState("")
    const [allcategories, setallcategories] = useState([])
    const [pageCount, setpageCount] = useState(1);
    const [search, setsearch] = useState("");
    const [slug, setSlug] = useState("")
    const pagetype = location.state.pagetype;
    const id = location?.state?.data?._id;
    console.log(location);
    useEffect(() => {
        setname(location?.state?.data?.name);
        setSlug(location?.state?.data?.slug)
        // setCategoryType(location?.state?.data?)
    }, [location]);
    console.log(categoryType, "cayehwfgefkqefqgfuehfwfwvww9999999999999999")
    const Category_function = async () => {
        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("name", name);
            fd.append("image", file);
            fd.append("slug", slug);
            fd.append("category", categoryType)

            try {
                let res = await addCondition_api(fd);
                if (res.data.status) {
                    navigate("/expertise-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            const fd = new FormData();
            fd.append("name", name);
            fd.append("image", file);
            fd.append("slug", slug);
            fd.append("category", categoryType);
            try {
                let res = await updateCondition_api(id, fd);
                if (res.data.status) {
                    navigate("/expertise-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };
    useEffect(() => {
        fetchallcategoriesFunc();
    }, []);


    async function fetchallcategoriesFunc(data) {
        try {
            const temp = {
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await getCategories_api(temp);
            if (res.data.status) {
                setallcategories(res?.data?.data.categories);
                // setType(res?.data?.data.categories[1]._id);
            } else {
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Condition Name</label>
                                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Condition Name" />
                            </div>
                        </Grid>

                        {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <input type="text" className="form-control" value={priority} onChange={(e) => setpriority(e.target.value)} placeholder="Priority" />
              </div>
            </Grid> */}
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Category Type</label>
                                {/* <input type="text" className="form-control"  onChange={(e) => setpriority(e.target.value)} placeholder="Priority" /> */}
                                <select className="form-control" onChange={(e) => setCategoryType(e.target.value)}>
                                    <option> select Type</option>
                                    {
                                        allcategories.map((data, index) => (
                                            <option value={data._id}>{data?.name}</option>
                                        ))
                                    }
                                    {/* <option value="Men">Men</option>
                                    <option value="Women">Women</option> */}
                                </select>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Slug</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="add slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                    placeholder="add slug"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Image (512 X 512)</label>
                                <div className="mr-2">
                                    <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                                </div>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <textarea
                  rows={3}
                  type="text"
                  className="form-control"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="description"
                />
              </div>
            </Grid> */}
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => Category_function()}>
                        <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
                    </div>
                </Card>
            </div >
        </>
    );
};

export default AddCondition;
