import React, { useState, useEffect } from "react";
import s from "./blog.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { updateBlog_api } from "../api/blog";
import { add_blog_api } from "../api/blog";
import RichTextEditor from "react-rte-17";

const AddBlog = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [title, settitle] = useState("");
    const [author, setAuthor] = useState("");
    const [description, setDescription] = useState("");
    const [file, setfile] = useState(null);
    const [status, setStatus] = useState("");
    const [slug, setSlug] = useState("")
    const [metaTag, setMetaTag] = useState("")
    const [engBlog, setEngBlog] = useState(RichTextEditor.createEmptyValue())
    // console.log(RichTextEditor.createValueFromString(engBlog, "html"), "englishs of the blg section the list of the")
    const pagetype = location.state.pagetype;
    // console.log(location.state.data, "location state data on the edit page of the blog listing in the siama")
    useEffect(() => {
        if (pagetype === "Edit") {
            settitle(location?.state?.data?.title);
            setSlug(location?.state?.data?.title)
            setMetaTag(location?.state?.data?.metaTag)
            setAuthor(location?.state?.data?.author);
            setStatus(location?.state?.data?.status);
            setDescription(location?.state?.data?.description);
            const initialEditorState = RichTextEditor.createValueFromString(
                location?.state?.data?.description,
                "html"
            );
            setEngBlog(initialEditorState)
        }
    }, [location]);

    const Category_function = async () => {
        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("title", title);
            fd.append("slug", slug);
            fd.append("metaTag", metaTag);
            fd.append("author", author);
            fd.append("image", file);
            fd.append("status", status);
            fd.append("description", engBlog.toString("html"));
            // fd.append("description", "this is testing");

            try {
                let res = await add_blog_api(fd);
                if (res.data.status) {
                    navigate("/blog-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            const fd = new FormData();
            fd.append("title", title);
            // fd.append("id", location?.state?.data?._id);
            fd.append("slug", slug);
            fd.append("metaTag", metaTag);
            fd.append("author", author);
            fd.append("image", file);
            fd.append("status", status);
            fd.append("description", engBlog.toString("html"));
            // fd.append("description", engBlog.toString("html"));
            try {
                let res = await updateBlog_api(fd, location?.state?.data?._id);
                if (res.data.status) {
                    navigate("/blog-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };

    const onChange1 = (value) => {
        console.log(value.toString("html"), "value data in the rich text editor")
        setEngBlog(value);
        if (PaymentResponse.onChange) {
            props.onChange(value.toString("html"));
        }
    }
    // const onChange1 = (value) => {
    //     setEngBlog(value);
    //     if (this.props.onChange) {
    //         this.props.onChange(
    //             value.toString('html')
    //         );
    //     }
    // };
    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Title</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        placeholder=""
                                        value={title}
                                        onChange={(e) => settitle(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Slug</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="slug"
                                        placeholder=""
                                        value={slug}
                                        onChange={(e) => setSlug(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Meta Tag</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="meta tag"
                                        placeholder=""
                                        value={metaTag}
                                        onChange={(e) => setMetaTag(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Image</label>
                                <div className="mr-2">
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="img"
                                        placeholder=""
                                        accept="image/*"
                                        onChange={(e) => setfile(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Author</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="priority"
                                        placeholder=""
                                        value={author}
                                        onChange={(e) => setAuthor(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Status</label>
                                <div className="  mr-2">
                                    <div className="form-group">
                                        <select
                                            className="form-control"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            id="exampleFormControlSelect1"
                                        >
                                            <option>Select Type </option>
                                            <option value={true}>True </option>
                                            <option value={false}>False</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Grid>



                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Description</label>
                                <RichTextEditor
                                    className="employment_jobdescribee"
                                    value={engBlog}
                                    onChange={onChange1}

                                />
                            </div>
                        </Grid>

                    </Grid>
                    <div
                        className={s["form-login-btn"]}
                        onClick={() => Category_function()}
                    >
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddBlog;
