import React from 'react'
import AddFaq from '../components/Faqs/AddFaq'

const AddFaqPage = () => {
    return (
        <div>
            <AddFaq />
        </div>
    )
}

export default AddFaqPage;
