import React from 'react'
import ExpertiseList from '../components/Conditions/ConditionList';

const ConditionPage = () => {
    return (
        <div>
            <ExpertiseList />
        </div>
    )
}

export default ConditionPage;
