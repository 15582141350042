import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./condition.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";

import { getBaseUrl2 } from "../../utils";
import { best_treatment_api, deleteCondition_api, get_condition_api, get_expertise_api } from "../api/condition";
import MultipleSelect from "./MultiSelector";
import { getCategories_api, getCategories_apis } from "../api/category";
import { notificationHandler } from "../../utils/Notification";
import { BiSearch } from "react-icons/bi";
const ExpertiseList = () => {

    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [allExpertise, setAllExpertise] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState();
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    const [type, setType] = useState("")
    const [multipleId, setMultipleId] = useState({})
    const [allcategories, setallcategories] = useState([])
    const [keys, setKeys] = useState("")

    console.log(deleteId, "deleteId delete iddddddddddd================================ on the contion page")

    useEffect(() => {
        fetchallcategoriesFunc();
    }, [pageCount, search]);

    async function fetchallcategoriesFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await getCategories_api(temp);
            if (res.data.status) {
                console.log(res.data.data, "reskdgeiweug")
                setallcategories(res?.data?.data.categories);
                setType(res?.data?.data.categories[1]._id);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAllExpertiseFunc();
    }, [pageCount, type]);

    async function fetchAllExpertiseFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                id: type
            };
            let res = await get_condition_api(temp);
            if (res.data.status) {
                // setpageLength(res?.data?.data?.toatal_page);
                setAllExpertise(res?.data?.data.conditions);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            setisLoading(false)
            console.log(error);
        }
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteuserFunc() {
        setisLoading(true);

        try {
            let res = await deleteCondition_api(deleteId);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setdeletePopup(false);
                fetchallcategoriesFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }



    return (
        <div className="container">
            <div className={s["article-list-title"]}>
                <h3>Condition List</h3>
            </div>
            <div className={s["user-list-heading"]}>
                <div className={s["user-list-search"]}>
                    <div className="beat_left">
                        <div
                            className={s["title"]}
                            onClick={() =>
                                navigate("/add-condition", {
                                    state: {
                                        pagetype: "Add",
                                    },
                                })
                            }
                        >
                            <IoMdAdd /> Condition
                        </div>
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Image</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allExpertise?.map((row) => (
                            <StyledTableRow key={row._id}>
                                <StyledTableCell>{row.image ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row?.image} alt="" /> : null}</StyledTableCell>
                                <StyledTableCell>{row.name} </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    <div style={{ cursor: "pointer" }} className={`${row.status === true ? s.active_admin : s.inactive_admin}`}>
                                        {row.status === true ? "Active" : "Inactive"}
                                    </div>
                                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                    <CiEdit
                                        onClick={() =>
                                            navigate("/add-condition", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <MdDelete
                                        onClick={() => {
                                            setdeletePopup(true);
                                            setcurrentGroup(row);
                                            setdeleteId(row._id);
                                        }}
                                        style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                    </TableBody>
                </Table>
                {allExpertise?.length <= 0 && <DataNotFound />}
                {allExpertise?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>
            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.skill_name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div >
    )
}

export default ExpertiseList;
