import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./blog.module.css";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import {
    deleteBanner_api,
    getBanners_api,
    statusUpdate_banner_api,
} from "../api/banner";
import { getBaseUrl2 } from "../../utils";
import { deleteBlog_api, get_blog_api } from "../api/blog";

const BlogData = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [allBlog, setAllBlog] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchallBlogFunc();
    }, [pageCount, search]);

    async function fetchallBlogFunc() {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 5,
                search: search.trim(),
            };
            let res = await get_blog_api(temp);
            if (res.data.status) {
                setAllBlog(res?.data?.data?.blogs);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteuserFunc() {
        setisLoading(true);

        try {
            let res = await deleteBlog_api(deleteId);
            if (res.data.status) {
                setisLoading(false);
                setdeletePopup(false);
                fetchallBlogFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    const banner_status = async (data) => {
        setisLoading(true);
        const fd = new FormData();
        fd.append("id", data.id);
        fd.append("status", data.status == true ? false : true);
        try {
            let res = await statusUpdate_banner_api(fd);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                // fetchallbannerFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    };

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Blog List</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                        <div className={s["search-box"]}>
                            <span style={{ paddingRight: "0.5rem" }}>
                                <BiSearch size={23} />
                            </span>
                            <input
                                type="text"
                                spellCheck="false"
                                onChange={(e) => {
                                    setpageCount(1);
                                    setsearch(e.target.value);
                                }}
                                placeholder="Search by name..."
                            />
                        </div>
                        <div className={s["filter-btn"]}>
                            <span style={{ paddingRight: "2px" }}>
                                <BiFilter size={20} />
                            </span>
                            Filter
                        </div>
                    </div>
                </div>
                <div className="beat_left">
                    <div
                        className={s["title"]}
                        onClick={() =>
                            navigate("/add-blog", {
                                state: {
                                    pagetype: "Add",
                                },
                            })
                        }
                    >
                        <IoMdAdd /> Blog
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Image</StyledTableCell>
                            <StyledTableCell align="center">Title</StyledTableCell>
                            <StyledTableCell align="center">Description</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {allBlog?.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    {row.image ? (
                                        <img
                                            style={{ height: "2rem", width: "3rem" }}
                                            src={getBaseUrl2() + row.image}
                                            alt=""
                                        />
                                    ) : null}
                                </StyledTableCell>

                                <StyledTableCell>{row?.title} </StyledTableCell>
                                <StyledTableCell>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: row?.description }}
                                    ></div>
                                </StyledTableCell>


                                <StyledTableCell align="center">
                                    <CiEdit
                                        onClick={() =>
                                            navigate("/add-blog", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <MdDelete
                                        onClick={() => {
                                            setdeletePopup(true);
                                            setcurrentGroup(row);
                                            setdeleteId(row._id);
                                        }}
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            cursor: "pointer",
                                        }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allBlog?.length <= 0 && <DataNotFound />}
                {allBlog?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination
                            count={pageLength}
                            size="large"
                            style={{ color: "#D21903" }}
                            onChange={(e, value) => setpageCount(value)}
                            page={pageCount}
                        />
                    </div>
                )}
            </div>

            <Dialog
                open={deletePopup}
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
                fullWidth="true"
                onClose={() => setdeletePopup(false)}
            >
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.category_name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div
                            className={s.employee_gl_popup}
                            onClick={() => setdeletePopup(false)}
                        >
                            Cancel
                        </div>
                        <div
                            className={s.employee_gl_popup_del}
                            onClick={() => deleteuserFunc()}
                        >
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div>
    );
};

export default BlogData;
