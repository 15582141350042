import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie"


const expertise_list = getBaseUrl() + "api/admin/condition";
const addCondition = getBaseUrl() + "api/admin/condition";
const all_service_list = getBaseUrl() + "api/admin/serviceByCat";
const best_treatments = getBaseUrl() + "api/admin/home";
const update_condition = getBaseUrl() + "api/admin/condition"

const deleteCategory = getBaseUrl() + "api/admin/condition"


export const get_condition_api = async (data) => {
    let config = {
        method: "get",
        url: expertise_list + `?type=${data.id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response
    }
}



export const get_all_service_list_dropdown = async (data) => {
    console.log(data, "hwgeufvgwgevwj82374354837946543433")
    let config = {
        method: "post",
        url: all_service_list,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response
    }
}

export const best_treatment_api = async (data) => {
    console.log(data, "data ------ in the api testing when choose the best treatments for the website")
    let config = {
        method: "post",
        url: expertise_list,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response
    }
}

export const addCondition_api = async (data) => {
    let config = {
        method: "post",
        url: addCondition,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const updateCondition_api = async (id, data) => {
    let config = {
        method: "patch",
        url: update_condition + `/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};


export const deleteCondition_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteCategory + `/${data}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        // data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
