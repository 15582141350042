import React from 'react'
import FaqList from '../components/Faqs/FaqList';

const FaqListPage = () => {
  return (
    <div>
      <FaqList />
    </div>
  )
}

export default FaqListPage;
