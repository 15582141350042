import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const booking = getBaseUrl2() + "api/admin/checkout";
const allocate_booking = getBaseUrl2() + "api/admin/assignVendor";
const updaterole = getBaseUrl() + "role/update";
const deleteRole = getBaseUrl() + "role/delete";
const confirm_booking = getBaseUrl2();

export const allocate_booking_api = async (data) => {
  let config = {
    method: "post",
    url: allocate_booking,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const role_update_api = async (data) => {
  let config = {
    method: "PATCH",
    url: updaterole,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const role_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: deleteRole,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const confirm_booking_api = async (temp, id) => {
  let config = {
    method: "PATCH",
    url: confirm_booking + `api/admin/confirm_booking/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: temp,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllrole = async (data) => {
  let config = {
    method: "get",
    // url: booking + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: booking,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
