import React, { useState, useEffect } from "react";
import s from "./SubCategories.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import Select from "react-select";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { fetchAllSkills } from "../api/skill";
import { getCategories_api } from "../api/category";
import RichTextEditor from "react-rte-17";
const BannerAdd = (props) => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const location = useLocation();
  const id = location?.state?.data?._id;
  const [description, setdescription] = useState("");
  const [allcategories, setallcategories] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const [type, settype] = useState("");
  const [file, setfile] = useState(null);
  const [rte, setrte] = useState(RichTextEditor.createEmptyValue());
  const [slug, setSlug] = useState("")
  const pagetype = location.state.pagetype;
  useEffect(() => {
    if (pagetype === "Edit") {
      setname(location?.state?.data?.name);
      setdescription(location?.state?.data?.description);
      setcategoryId(location?.state?.data?.category?._id);
      setSlug(location?.state?.data?.slug)
      const initialEditorState = RichTextEditor.createValueFromString(location?.state?.data?.description, "html");
      setrte(initialEditorState);
    }
  }, [location]);

  // console.log(name);
  // console.log(description);
  // console.log(categoryId);
  const Category_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", rte.toString("html"));
      fd.append("cat_id", categoryId);
      fd.append("slug", slug);
      fd.append("image", file);
      // fd.append("price", price);
      try {
        let res = await addBanner_api(fd);
        if (res.data.status) {
          navigate("/sub-categories-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", rte.toString("html"));
      fd.append("cat_id", categoryId);
      fd.append("slug", slug);
      fd.append("image", file);
      // fd.append("price", price);
      try {
        let res = await updateBanner_api(id, fd);
        if (res.data.status) {
          navigate("/sub-categories-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const onChange = (value) => {
    console.log("Rajat\n", value);
    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  useEffect(() => {
    getAllservices();
  }, []);
  const getAllservices = async () => {
    const temp = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      let res = await getCategories_api(temp);
      if (res.data.status) {
        setallcategories(res?.data?.data.categories);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Category </label>
                <select class="form-select" value={categoryId} onChange={(e) => setcategoryId(e.target.value)} aria-label="Default select example">
                  <option selected>Category Name</option>
                  {allcategories.map((data, index) => (
                    <option key={index} value={data?._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Sub Category Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Sub Category Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Slug</label>
                <input
                  type="text"
                  className="form-control"
                  name="add slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  placeholder="add slug"
                />
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Price</label>
                <input
                  type="text"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="price"
                />
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image (250 * 300 px)</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <textarea
                  rows={3}
                  type="text"
                  className="form-control"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="Description"
                />
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <RichTextEditor value={rte} className="employment_jobdescribee" onChange={onChange} />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => Category_function()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BannerAdd;
