import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getFaq = getBaseUrl2() + "api/admin/faq";
// const statusUpdate_banner = getBaseUrl2() + "";
const deleteFaq = getBaseUrl2() + "api/admin/faq";
const addFaq = getBaseUrl2() + "api/admin/faq";
const updateFaq = getBaseUrl2() + "api/admin/faq";

export const updateFaq_api = async (id, data) => {
    let config = {
        method: "patch",
        url: updateFaq,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: {
            faqId: id,
            question: data.question,
            answer: data.answer
        },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const addFaq_api = async (data) => {
    let config = {
        method: "post",
        url: addFaq,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: { faq: data },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const deleteFaq_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteFaq,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: { faqId: data },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
// export const statusUpdate_banner_api = async (id, data) => {
//     let config = {
//         method: "patch",
//         url: statusUpdate_banner + `api/admin/subcategory/${id}`,
//         headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
//         data: data,
//     };

//     try {
//         let res = await axios(config);
//         return res;
//     } catch (error) {
//         console.log(error);
//         return error.response;
//     }
// };
export const getFaq_api = async (data) => {
    let config = {
        method: "get",
        // url: getFaq + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        url: getFaq + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}`, "Content-Type": "application/json" },
        // data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
