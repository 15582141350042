import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import s from "./bestDeal.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { getroll_admin_api, update_admin_api, update_banner_api } from "../api/admin";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { create_best_deal, update_best_Deal } from "../api/bestDeal";
const AddBestDeal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location?.state?.data?._id;
    const [isLoading, setisLoading] = useState(false);
    const [title, settitle] = useState("");
    const [status, setstatus] = useState("");
    const [priority, setpriority] = useState("");
    const [file, setfile] = useState(null);
    const [url, setUrl] = useState("")
    const pagetype = location.state.pagetype;
    useEffect(() => {
        settitle(location?.state?.data?.title);
        setstatus(location?.state?.data?.status);
        setpriority(location?.state?.data?.priority);
        setUrl(location?.state?.data?.hyperlink);
    }, [location]);

    const create_bestDeal = async () => {
        console.log(pagetype == "Add");

        if (!blankValidator(title)) {
            alert("Please enter title");
            return;
        }
        if (pagetype == "Add") {
            if (!blankValidator(status)) {
                alert("Please Select status");
                return;
            }
        }

        if (!blankValidator(priority)) {
            alert("Please enter priority Number");
            return;
        }

        if (pagetype == "Add") {
            const fd = new FormData();
            fd.append("title", title);
            fd.append("priority", priority);
            fd.append("status", status);
            // https://siama.in/booking?key=id&id=66545d890c42d4097a3533f1&sId=6654683cf56317fe8857b7a8
            fd.append("hyperlink", JSON.stringify(url));
            fd.append("banner_image", file);

            try {
                let res = await create_best_deal(fd);
                if (res.data.status) {
                    navigate("/best-deal");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            const fd = new FormData();
            fd.append("title", title);
            fd.append("priority", priority);
            fd.append("status", status);
            fd.append("hyperlink", JSON.stringify(url));
            fd.append("banner_image", file);
            try {
                let res = await update_best_Deal(id, fd);
                if (res.data.status) {
                    navigate("/best-deal");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };
    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Title</label>
                                <input type="text" className="form-control" name="title" value={title} onChange={(e) => settitle(e.target.value)} placeholder="Title" />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Image ( 1519 × 476 px)</label>
                                <div className="mr-2">
                                    <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Priority</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="Priority"
                                    value={priority}
                                    onChange={(e) => setpriority(e.target.value)}
                                    placeholder="Priority"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Status</label>
                                <div className="  mr-2">
                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect1" value={status} onChange={(e) => setstatus(e.target.value)} name="status">
                                            <option value="">Select Role</option>
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Redirect Url</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="url"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    placeholder="redirect url"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div className={s["form-login-btn"]} onClick={() => create_bestDeal()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddBestDeal;
